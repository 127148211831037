export default function getForesightMinuteOptions($t: (txt: string) => string): Array<{ id: number; name: string }> {
  const tHours = $t('hours')
  const tMinutes = $t('minutes')
  return [
    { id: 0, name: $t('c:booking-foresight:None') },
    { id: 5, name: '5 ' + tMinutes },
    { id: 10, name: '10 ' + tMinutes },
    { id: 15, name: '15 ' + tMinutes },
    { id: 20, name: '20 ' + tMinutes },
    { id: 30, name: '30 ' + tMinutes },
    { id: 60, name: '60 ' + tMinutes },
    { id: 120, name: '2 ' + tHours },
    { id: 180, name: '3 ' + tHours },
    { id: 240, name: '4 ' + tHours },
    { id: 360, name: '6 ' + tHours },
    { id: 480, name: '8 ' + tHours },
    { id: 720, name: '12 ' + tHours },
    { id: 1440, name: '24 ' + tHours },
    { id: 2160, name: '36 ' + tHours },
    { id: 2880, name: '48 ' + tHours },
    { id: 3600, name: '60 ' + tHours },
    { id: 4320, name: '72 ' + tHours },
    { id: 5040, name: '84 ' + tHours },
    { id: 5760, name: '96 ' + tHours },
    { id: 1800, name: '30 ' + tHours },
    { id: 3600, name: '60 ' + tHours },
    { id: 5400, name: '90 ' + tHours },
    { id: 7200, name: '120 ' + tHours },
    { id: 8640, name: '144 ' + tHours },
    { id: 10080, name: '168 ' + tHours },
    { id: 11520, name: '192 ' + tHours },
    { id: 31536000, name: '1 year' },
  ]
}
