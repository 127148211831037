




// When using this, make sure you implement validation on calling view, to not allow proceeding without a valid time
//   for example you can check at saveRule() of src/views/booking/settings/schedule/Edit.vue
import Vue from 'vue'
import Component from 'vue-class-component'
import { Prop } from 'vue-property-decorator'
import { mask } from 'vue-the-mask'

@Component({
  directives: {
    mask,
  },
})
export default class TimeField extends Vue {
  @Prop({ default: null })
  private label: string

  @Prop({ type: [String, Array] })
  private value: string | string[]

  private rules = {}

  private get input() {
    return this.value
  }

  private set input(value) {
    this.$emit('input', value)
  }

  private mounted() {
    this.rules = {
      timeRules: [
        (v) => !v || /^(?:\d|[01]\d|2[0-3]):[0-5]\d$/.test(v) || this.$t('c:validation:Invalid time (ex: HH:mm)'),
      ],
    }
  }
}
